import React from 'react'
import Layout from '../components/layout'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Link } from "gatsby"


const termsPage = () => (
  <Layout>
    <div style={styles.divStyle}>
    <Link to="/">
    <p style={{color:"blue"}}>Go back to main page</p></Link>
   <article><h2>Term and Conditions</h2>
  <p> Welcome to our website. If you continue to browse and use this website you are agreeing to comply with and be bound by the following terms and conditions of use, which together with our privacy policy govern Fudō-An Tea & Wellness Services relationship with you in relation to this website.
    
    The term Fudō-An Tea & Wellness Services or ‘us’ or ‘we’ refers to the owner of the website. The term ‘you’ refers to the user or viewer of our website.</p>
<br /><br />
<h2>The use of this website is subject to the following terms of use:</h2>
<p>
The content of the pages of this website is for your general information and use only. It is subject to change without notice.
Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness or suitability of the information and materials found or offered on this website for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.
Your use of any information or materials on this website is entirely at your own risk, for which we shall not be liable. It shall be your own responsibility to ensure that any products, services or information available through this website meet your specific requirements.
This website contains material which is owned by or licensed to us. This material includes, but is not limited to, the design, layout, look, appearance and graphics. Reproduction is prohibited other than in accordance with the copyright notice, which forms part of these terms and conditions.
All trademarks reproduced in this website which are not the property of, or licensed to, the operator are acknowledged on the website.
Unauthorised use of this website may give rise to a claim for damages and/or be a criminal offence.
From time to time this website may also include links to other websites. These links are provided for your convenience to provide further information. They do not signify that we endorse the website(s). We have no responsibility for the content of the linked website(s).
You may not create a link to this website from another website or document without our prior written consent.</p>

<h4>Refund Policy</h4>
<p>
We offer a 3 days unconditional satisfaction guarantee. If you are not satisfied with our service, we will provide you with a full refund, no questions asked. Refund requests beyond 3 days are handled on a per client basis.
</p>
<h3>Privacy Policy</h3>
<p>This privacy policy sets out how Fudō-An Tea & Wellness Services uses and protects any information that you give Fudō-An Tea & Wellness Services when you use this website.
<br />
Fudō-An Tea & Wellness Services is committed to ensuring that your privacy is protected. Should we ask you to provide certain information by which you can be identified when using this website, then you can be assured that it will only be used in accordance with this privacy statement.
<br />
Fudō-An Tea & Wellness Services may change this policy from time to time by updating this page. You should check this page from time to time to ensure that you are happy with any changes.
</p>
<h4>What we collect</h4>
<p>We may collect the following information:
<br /><br />
- Name<br />
- Contact information including email address<br />
- Demographic information such as postcode, preferences and interests<br />
- Other information relevant to customer surveys and/or offers<br />
</p>
<h4>What we do with the information we gather</h4>
<p>We require this information to understand your needs and provide you with a better service, and in particular for the following reasons:
<br />
- Internal record keeping.<br />
- We may use the information to improve our products and services.<br />
- We may periodically send promotional emails about new products, special offers or other information which we think you may find interesting using the email address which you have provided.<br />
- From time to time, we may also use your information to contact you for market research purposes. We may contact you by email, phone, fax or mail. We may use the information to customise the website according to your interests.<br />
</p>
<h4>Security</h4>
<p>We are committed to ensuring that your information is secure. In order to prevent unauthorised access or disclosure we have put in place suitable physical, electronic and managerial procedures to safeguard and secure the information we collect online.
</p>
<h4>How we use cookies</h4>
<p>A cookie is a small file which asks permission to be placed on your computer’s hard drive. Once you agree, the file is added and the cookie helps analyse web traffic or lets you know when you visit a particular site. Cookies allow web applications to respond to you as an individual. The web application can tailor its operations to your needs, likes and dislikes by gathering and remembering information about your preferences.

We use traffic log cookies to identify which pages are being used. This helps us analyse data about webpage traffic and improve our website in order to tailor it to customer needs. We only use this information for statistical analysis purposes and then the data is removed from the system.

Overall, cookies help us provide you with a better website, by enabling us to monitor which pages you find useful and which you do not. A cookie in no way gives us access to your computer or any information about you, other than the data you choose to share with us.

You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. This may prevent you from taking full advantage of the website.
</p>
<h3>Links to other websites</h3>
<p>Our website may contain links to other websites of interest. However, once you have used these links to leave our site, you should note that we do not have any control over that other website. Therefore, we cannot be responsible for the protection and privacy of any information which you provide whilst visiting such sites and such sites are not governed by this privacy statement. You should exercise caution and look at the privacy statement applicable to the website in question.
</p>
<h3>Controlling your personal information</h3>
<p>You may choose to restrict the collection or use of your personal information in the following ways:<br />

- Whenever you are asked to fill in a form on the website, look for the box that you can click to indicate that you do not want the information to be used by anybody for direct marketing purposes. <br />
- If you have previously agreed to us using your personal information for direct marketing purposes, you may change your mind at any time by writing to or emailing us at aislynn@fudo-an.com. This e-mail address is being protected from spambots. You need JavaScript enabled to view it . <br />
- We will not sell, distribute or lease your personal information to third parties unless we have your permission or are required by law to do so. We may use your personal information to send you promotional information about third parties which we think you may find interesting if you tell us that you wish this to happen. <br />
<br />
You may request details of personal information which we hold about you under the Data Protection Act 1998. A small fee will be payable. <br />

If you believe that any information we are holding on you is incorrect or incomplete, please write to or email us as soon as possible. We will promptly correct any information found to be incorrect. To request a copy of the information disclosure provided by Fudō-An Tea & Wellness Services) pursuant to Section 1798.83 of the California Civil Code, please contact us via email to aislynn@fudo-an.com, or by writing us at the following address:
<br />
<br />
Fudō-An Tea & Wellness Services <br />
2822 W. 28th Ave <br />
Apt 208 <br />
Denver, CO 80211 <br />
<br />
Please allow 30 days for a response. 
</p>
</article>
</div>
</Layout>
)
var styles = {
  divStyle: {
    maxWidth:700
  }
};

export default termsPage
